import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { pay_refund } from "../../../../../Studio-Services/Studio_Services";
import { useLocation, useNavigate } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";
import moment from "moment";
import logo from '../../../../../Images/logo_white_web.png';
import Swal from "sweetalert2";

export const IndividualTransactionDetails = () => {
  const location = useLocation();
  const [localdata, setLocaldata] = useState();
  // const [totalData, setTotalData] = useState({ 'item': '', 'price': '', 'discount': '', 'tax1': '', 'tax2': '', 'tip': '', 'total': '' });

  const [totalData, setTotalData] = useState({ 'item': '', 'basePrice': 0, "quantity": 0, 'discount': 0, "adjPrice": 0, "tip": 0, 'tax1': 0, 'tax2': 0, 'total': 0 });

  const [show, setShow] = useState(false);
  const [notshow, setNotshow] = useState(false);
  const [note, setNote] = useState();
  const [noteError, setNoteError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.state) {
      let e = location.state;

      let pre = { ...totalData };
      setLocaldata(location.state);

      let tp = 0.00;
      if (e.tip_comment) { tp = parseFloat(e.tip_comment); }

      // if (e.tip_comment != '' && !isNaN(parseFloat(e.tip_comment))) { tp = parseFloat(e.tip_comment); }

      // let ds = 0;
      // let tx1 = 0;
      // let tx2 = 0;
      // let full_final = 0;
      // let tt_tex = 0;
      // let bs = 0;
      // let fnl = 0;


      // ds = parseFloat(e.finalDiscount).toFixed(2);
      // tx1 = parseFloat(e?.finalTax1);
      // tx2 = parseFloat(e?.finalTax2);
      // tt_tex = parseFloat(tx1) + parseFloat(tx2);
      // bs = parseFloat(e?.finalBaseAmount); //- parseFloat(tt_tex).toFixed(2);
      // fnl = parseFloat(e?.finalSubTotal);  //finalAMT(e.amount, tx1, tx2);
      // full_final = parseFloat(e?.finalSubTotal);



      pre.item = e.item_name;
      pre.basePrice = parseFloat(e?.finalBaseAmount);
      pre.quantity = parseInt(e.quantity);
      pre.discount = parseFloat(e?.finalDiscount);
      pre.adjPrice = parseFloat(e?.finalAdjustPrice);
      pre.tip = tp;
      pre.tax1 = parseFloat(e?.finalTax1);
      pre.tax2 = parseFloat(e?.finalTax2);
      pre.total = (parseFloat(e?.finalSubTotal) + parseFloat(tp));




      // pre.discount = ds;
      // pre.tax1 = tx1;
      // pre.tax2 = tx2;
      // pre.tip = tp;
      // pre.total = full_final;
      setTotalData(pre);
    } else setTotalData({ 'item': '', 'basePrice': 0, "quantity": 0, 'discount': 0, "adjPrice": 0, "tip": 0, 'tax1': 0, 'tax2': 0, 'total': 0 });
  }, [location])


  const refundHandler = async (mydata) => {
    if (!note) { setNoteError('Required'); return; }
    setNotshow(false);
    Swal.fire({
      html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
          <h4 class="title text-uppercase text-center mt-4 textWhite">CONFIRMATION</h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">Do you want to refund this transaction ?</small>
          </p>
        `,
      showClass: {
        popup: "custom-dialog-container",
      },
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const bkid = mydata.id;
        const tr_data_id = mydata.transaction_id;
        const fdata = { "user_booking_id": bkid, "tansaction_id": tr_data_id, "refundNote": note };
        const resp = await pay_refund(fdata);
        if (resp) {
          Swal.fire({
            html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt=''/>
              <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
              <p class="text-center my-4 textcolor">
                <small class="textWhite">${resp.data.message}</small>
              </p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.value) {
              navigate("/transactionreport");
            }
          })
        }
      }
    });

  }


  const pymodeHandler = (mode) => {
    const premode = mode.replace(/\s/g, '').toLowerCase();
    if (premode === 'visa' || premode === 'mastercard' || premode === 'amex' || premode === 'cron') {
      return "Credit/Debit";
    }
    return mode;
  }


  const noteHandler = (e) => {
    setNoteError();
    setNote(e.target.value);
  };

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <Row>
                        <Col>
                          <h4 className="mb-4 "> Individual Transaction Details </h4>
                        </Col>
                        <Col className="text-right">
                          <SharedButton
                            title={"Back"}
                            onClick={() => { window.history.back(); }}
                            className={"backBtn"}
                            type={"button"}
                          />
                        </Col>
                      </Row>
                      {localdata &&
                        <>
                          <Row>
                            <Col>
                              <div className="DetailsT">
                                <p><b>Date/Time : </b> {moment.unix(localdata.update_dt).format('DD-MMM-YYYY ,hh:mm A')} </p>
                                <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Client : </b> <h5 className="mx-2">{localdata.name + ' ' + localdata.lastname}</h5></span>
                                <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Method : </b> <h5 className="mx-2">{localdata.payment_mode ? pymodeHandler(localdata.payment_mode) : "Credit Card"}</h5> </span>
                                <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Trans# : </b> <h5 className="mx-2">{localdata.transaction_id} </h5></span>
                                <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Note : </b> <h5 className="mx-2">{localdata.payment_note}</h5> </span>
                                {localdata.refund_status == 1 &&
                                  <>
                                    <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Refunded : </b> <h5 className="mx-2" style={{ color: 'red' }}>{"Yes"}</h5> </span>
                                    <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Refund Date : </b> <h5 className="mx-2">{moment(localdata.refund_date).format("DD-MMM-YYYY")}</h5> </span>
                                    <span style={{ display: 'flex', marginBottom: '0px' }}> <b>Refund Note : </b> <h5 className="mx-2">{localdata.refundNote}</h5> </span>
                                  </>
                                }
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    {/* <th>Item Purchased</th>
                                    <th>Price</th>
                                    <th>Discount</th>
                                    <th>Tax 1</th>
                                    <th>Tax 2</th>
                                    <th>Tip</th>
                                    <th>Total</th> */}
                                    <th>Item Purchased</th>
                                    <th>Base Amount</th>
                                    <th>Quantity</th>
                                    <th>Discount</th>
                                    <th>Adj Price</th>
                                    <th>Tip</th>
                                    <th>Tax 1</th>
                                    <th>Tax 2</th>
                                    <th>Sub Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{totalData.item}</td>
                                    <td>$ {parseFloat(totalData.basePrice).toFixed(2)}</td>
                                    <td> {parseInt(totalData.quantity) === 0 ? 1 : parseInt(totalData.quantity)}</td>
                                    <td>$ {parseFloat(totalData.discount).toFixed(2)}</td>
                                    <td>$ {parseFloat(totalData.adjPrice).toFixed(2)}</td>
                                    <td>$ {totalData.tip}</td>
                                    <td>$ {parseFloat(totalData.tax1).toFixed(2)}</td>
                                    <td>$ {parseFloat(totalData.tax2).toFixed(2)}</td>
                                    <td>$ {parseFloat(totalData.total).toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-end">
                              {localdata.refund_status == 0 &&
                                <button type="button" className="btn btn-success m-1 btshadow" onClick={() => setNotshow(true)}>Refund / Void</button>
                              }
                            </Col>
                          </Row>
                        </>
                      }
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal show={notshow} size="md">
        <Modal.Body >
          <Row className="mt-3">
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Write Short Note:- <span style={{ color: 'red' }}>*</span></label>
              <input class="form-control" name="refundNote" onChange={noteHandler}></input>
              <small className="error">{noteError}</small>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col d-flex" style={{ justifyContent: 'center' }}>
              <button type="button" className="btn btn-success m-1 btshadow" onClick={() => refundHandler(localdata)}>Done</button>
              <button type="button" className="btn m-1 btshadow" style={{ background: '#6e7881', color: 'white' }} onClick={() => { setNotshow(false); }}>Cancel</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Refunded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Item Purchased</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Tax 1</th>
                    <th>Tax 2</th>
                    <th>Tip</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ background: 'red', color: "#FFF" }}>{totalData.item}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {parseFloat(totalData.price).toFixed(2)}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {parseFloat(totalData.discount).toFixed(2)}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {parseFloat(totalData.tax1).toFixed(2)}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {parseFloat(totalData.tax2).toFixed(2)}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {totalData.tip}</td>
                    <td style={{ background: 'red', color: "#FFF" }}>- $ {parseFloat(totalData.total).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-success m-1 btshadow" onClick={() => { setShow(false); navigate('/transactionreport') }}>Done</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
