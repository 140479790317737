import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { cardGet, clover_pay_checkout_single } from "../Studio-Services/Studio_Services";
import { useEffect } from "react";
import { useState } from "react";
import logo from '../Images/logo_white_web.png'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SaveCardDetail = ({ data, clientId, setLoder ,tablePrice}) => {
  const [saveCards, setSaveCards] = useState([]);
  const [cardsaveID, setCardsaveID] = useState('');
  const [errorcvv, setErrorcvv] = useState('');
  const [cvvValue, setCVVValue] = useState('');
  const [cardData, setCardData] = useState();
  const [crtData, setCrtData] = useState();
  const [client_id, setClient_id] = useState();
  const [memberId, setMemberId] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    if (data) {
      setCrtData(data);
    }
  }, [data])

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;

    setCardsaveID(selectedValue);
    // getCardType(event);
  };


  const getCardDetails = async () => {
    const fData = {
      "userid": client_id,
    }
    const resp = await cardGet(fData);
    if (resp) {
      setSaveCards(resp.data.data);
    }
  };

  useEffect(() => {
    setMemberId(localStorage.getItem("memberUserId"));
    if (localStorage.getItem('clientID')) {
      setClient_id(localStorage.getItem('clientID'));
      getCardDetails();
    } else if (clientId) {
      setClient_id(clientId);
      getCardDetails();
    }
  }, [clientId])

  const errorHandler = (event) => {
    const sanitizedValue = event.target.value.replace(/^\s+/g, '').slice(0, 4);
    setCVVValue(sanitizedValue);
    setErrorcvv('');
  }

  const setDataCart = () => {
    if (crtData && crtData.business_details && crtData.business_details.cart_details) {
      const abc = [];
      const bsID = crtData.business_details.business_id;
      crtData.business_details.cart_details.map((e) => (
        abc.push({
          "service_id": e.service_id,
          "service_type": e.service_type,
          "business_id": bsID,
          "quantity": e.quantity,
          "amount": e.sub_total,
          "tax": e.tax,
          "discount": e.discount
        })
      ))
      setCardData(abc);
    }
  }
  useEffect(() => {
    setDataCart();
  }, [crtData]);

  const paymentViaSaveCard = async (card) => {
    let isValid = 1;

    // if (!cvvValue) {
    //   setErrorcvv('CVV is required');
    //   isValid = 0;
    //   return;
    // }

    if (isValid === 1) {
      setLoder(true);
      const payload = {
        "member_id": memberId || '',
        "client_id": client_id || '',
        "token": card.card_token,
        "savecard": 1,
        "card_id": card.card_id,
        "data": cardData,
        "business_id": "",
        "country_code": "",
        'priceData': tablePrice,
      }

      const resp = await clover_pay_checkout_single(payload);
      if (resp) {
        setLoder(false);
        setCVVValue('');
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">${resp.data.message}</small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        }).then((result) => {
          if (result.isConfirmed) {
            if (JSON.parse(localStorage.getItem('st_isPassPurchaseFlow'))) {
              let getFinale = JSON.parse(localStorage.getItem('allsetData'));
              navigate('/client-search', { state: { back: 'pre', class_id: getFinale.class_id, schedule_id: getFinale.schedule_id, serchKey: JSON.parse(localStorage.getItem('st_searchedClients')) } });
            } else {
              navigate("/make-a-purchase");
            }
          }
        });
      }
      setLoder(false);
    }
    setLoder(false);
  }


  return (
    <Row >

      <div class="card-details" >
        <h5 class="payment-head p-3 font-arialrouded text-capitalize">Saved Cards</h5>
        <div>
          {saveCards && saveCards.length > 0 ?
            // *ngFor="let cardsave of saveCards"
            <div class="payment-history-box white-bg border-radius6 box-shadow1 p-3 mb-3">
              {saveCards.map((cardsave) => (
                <div class="row mx-0">
                  <div class="col-2 pl-0">
                    {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') && <img src="assets/images/visa_card.png" class="img-fluid" />}
                    {cardsave.card_type == 'MC' && <img src="assets/images/master_card.png" class="img-fluid" />}
                    {cardsave.card_type == 'AM' && <img src="assets/images/american_express.png" class="img-fluid" />}
                  </div>
                  <div class="col-8">
                    <p class="mb-0 font-14 font-w-500 dark-color"> {cardsave.number} </p>
                    <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YY) </h5>
                    <p class="mb-0 font-12 dark-color"> {cardsave.expiry_month}  /
                      {cardsave.expiry_year} </p>
                  </div>
                  <div class="col-2 text-right">
                    <input
                      style={{ background: cardsaveID === cardsave.card_id ? '#0075ff' : '', borderRadius: '10px' }}
                      type="radio"
                      name="pass_radio"
                      id={cardsave.card_id}
                      className="pass_input_checkbox form-control"
                      value={cardsave.card_id}
                      checked={cardsaveID === cardsave.card_id}
                      onChange={handleRadioChange}
                    />
                  </div>
                  {cardsaveID == cardsave.card_id ?
                    <form autoComplete="off">
                      <div class="text-center d-flex flex-wrap mt-4" style={{
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        {/* <div className=""> */}
                        {/* <input
                          type="password"
                          autoComplete="off"
                          className={`form-control w-25 mr-5 ml-5`}
                          placeholder="CVV"
                          pattern="\d{3,4}"
                          maxLength={3}
                          name="cvv"
                          value={cvvValue}
                          onChange={errorHandler}
                        /> */}

                        {/* </div> */}
                        {/* <div className="col-6"> */}
                        <button class="btn btn-brandbluegrad d-block w-50" type="button"
                          onClick={() => paymentViaSaveCard(cardsave)}
                        >Submit</button >
                        {/* </div> */}
                      </div >
                      <div class="text-center d-flex flex-wrap">  <small className="text-danger">{errorcvv}</small></div>
                    </form >
                    : ''}
                </div >
              ))}
            </div >
            :
            <p class="text-center"
            // * ngIf="saveCards.length == 0"
            > No Record Found</p >
          }
        </div >
      </div >
      {/* </Col > */}
    </Row >
  );
};

export default SaveCardDetail;
