import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import logo from "../../../../../Images/logo_white_web.png"
import { book_services, get_member_list } from '../../../../../Studio-Services/Studio_Services';
import { useNavigate } from 'react-router-dom';

export const StepFour = ({ cardDetail, slotDate, slotTime, setStep, shiftData }) => {
    const [costomerDetail, setCostomerDetail] = useState();
    const [serviceDeatil, setServiceDetail] = useState();
    const [instDetails, setInstDetails] = useState();
    const [userType, setUserType] = useState("self");
    const [slotDateTime, setslotDateTime] = useState();
    const [slotFullDetail, setSlotFullDetail] = useState();
    const [familyDetails, setFamilyDetails] = useState();
    const navigate = useNavigate();


    useEffect(() => {
        setServiceDetail(cardDetail.sData)
        setInstDetails(cardDetail.iData);
        setCostomerDetail(cardDetail.ClientFullDetails[0]);
        setslotDateTime(slotTime)
        setSlotFullDetail(slotTime);
    }, [cardDetail, slotTime, slotDate]);

    const ageCalculator = (dob) => {
        if (dob) {
            const birthdate = new Date(dob);
            const currentDate = new Date();

            const ageInMillis = currentDate - birthdate; // Difference in milliseconds
            const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25); //
            return Math.floor(ageInYears);
        }
        return;
    }

    const taxCalculator = (amount, tax) => {
        if (tax === 0) {
            return 0;
        } else {
            const taxAmount = (+tax / 100) * +amount;
            return taxAmount;
        }
    }
    const grandTotal = (amount, tax1, tax2) => {
        const total = parseFloat(amount) + parseFloat(tax1) + parseFloat(tax2);
        const grand = total.toFixed(2);
        return grand;
    };

    const cancelHandler = () => {
        Swal.fire({
            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          CANCEL POLICY
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        ${serviceDeatil && serviceDeatil.cancel_policy ? serviceDeatil.cancel_policy : "No Policy"} </small></p>
          `,
            showClass: {
                popup: 'custom-dialog-container',
            },
            customClass: {
                confirmButton: 'custom-button custom-button-size modalbtn',
            },
        });
    }
    const getFamilyDetail = async () => {
        setUserType("family");
        const fData = {
            "customer_id": costomerDetail.id,
        }
        const resp = await get_member_list(fData);
        if (resp) {

            setFamilyDetails(resp.data);
        }
    }

    const finalSubmitHandler = async (e) => {
        e.preventDefault();
        const Tex1 = taxCalculator(serviceDeatil.amount, serviceDeatil.tax1_rate);
        const Tax2 = taxCalculator(serviceDeatil.amount, serviceDeatil.tax2_rate);

        const grnTotal = grandTotal(serviceDeatil.amount, Tex1, Tax2);


        let finalAmount = 0;
        let tex1Amount = 0;
        let tex2Amount = 0;
        let serviceAmount = serviceDeatil?.amount || 0;

        if (serviceDeatil && serviceDeatil.amount) {
            finalAmount = grandTotal(serviceDeatil.amount, taxCalculator(serviceDeatil.amount, serviceDeatil.tax1_rate), taxCalculator(serviceDeatil.amount, serviceDeatil.tax2_rate));
        }
        if (serviceDeatil && serviceDeatil.amount && serviceDeatil.tax1_rate) {
            tex1Amount = taxCalculator(serviceDeatil.amount, serviceDeatil.tax1_rate);
        }
        if (serviceDeatil && serviceDeatil.amount && serviceDeatil.tax2_rate) {
            tex2Amount = taxCalculator(serviceDeatil.amount, serviceDeatil.tax2_rate);
        }

        const fValue = {
            "service_type": 2,
            "token": 123,
            "quantity": 1,
            "service_id": serviceDeatil.id,
            "customer": costomerDetail.id,
            "instructor_id": instDetails.id,
            "tax1": serviceDeatil.tax1_rate,
            "tax2": serviceDeatil.tax2_rate,
            "taxAmount": Tex1 + Tax2,
            "grand_total": grnTotal,
            "slot_date": slotFullDetail.shift_date,
            "shift_id": shiftData.shift_id,///////
            "schedule_id": shiftData.shift_schedule_id, /////
            "start_time_unix": slotFullDetail.start_time_unix,
            "end_time_unix": slotFullDetail.end_time_unix,
            "shift_date": slotFullDetail.shift_date,
            "family_user_id": 0,

            'finalBaseAmount': serviceAmount,
            'finalPrice': serviceAmount,
            'finalDiscount': 0,
            'finalAdjustPrice': serviceAmount,
            'finalTax1': tex1Amount,
            'finalTax2': tex2Amount,
            'finalSubTotal': finalAmount,
        }

        const resp = await book_services(fValue);
        if (resp) {
            Swal.fire({
                html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/service-appointment");
                }
            });
        }

    }
    return (
        <>
            {/* <!-- Start Of Step 4 --> */}

            <div className="row">
                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div className="section-heading statbox-heading p-3 m-3 statbox" >
                        <div className="pb-3 border-bottom">
                            <div className="row">
                                <div className="col-12">
                                    {/* <h1>STEP 4</h1> */}
                                    <h5 className="title black-color text-uppercase mb-0 pb-3"> customer details </h5>
                                </div>
                            </div>
                            <div calss="row">
                                <div className="form-group profile-gender mb-0">
                                    <div className="profile-gender-button">
                                        <input type="radio" id="self" name="radio" value="self"
                                            checked={userType === 'self'}
                                            onChange={() => setUserType("self")} />
                                        <label className="font-16" for="self"> Self </label>
                                        <input type="radio" id="family" name="radio" value="family"
                                            checked={userType === 'family'}
                                            onChange={getFamilyDetail} />
                                        <label className="font-16" for="family"> Family </label>
                                    </div>
                                </div>
                            </div>
                            {userType === "self" ?
                                <div className="row ng-star-inserted pt-2">
                                    <div className="col-6 col-md-5 text-left mb-2">
                                        <p className="black-color font-14 mb-0"> Name </p>
                                        <p className="black-color font-16"> {costomerDetail && costomerDetail.name ? costomerDetail.name : ""} </p>
                                    </div>
                                    <div className="col-6 col-md-3 text-left mb-2 pl-2 pl-md-0">
                                        <p className="black-color font-14 mb-0"> Gender </p>
                                        <p className="black-color font-16"> {costomerDetail && costomerDetail.gender ? costomerDetail.gender : ""} </p>
                                    </div>
                                    <div className="col-6 col-md-3 text-left mb-2">
                                        <p className="black-color font-14 mb-0"> Age </p>

                                        <p className="black-color font-16"> {costomerDetail && costomerDetail.date_of_birth ? ageCalculator(costomerDetail.date_of_birth) : ""} </p>
                                    </div>
                                </div>
                                : familyDetails ?

                                    <div className="row ng-star-inserted pt-2" >
                                        <div className="col-2 col-md-2 text-left mb-2">
                                            <p className="black-color font-14 mb-0"> &nbsp; </p>
                                            <p className="black-color font-16">
                                                <input type="radio" name="familyRadio"
                                                    value="{{usr.memeber_id}}"
                                                // [(ngModel)] = "family_user_id"
                                                />
                                            </p>
                                        </div>
                                        <div className="col-5 col-md-5 text-left mb-2">
                                            <p className="black-color font-14 mb-0"> Name </p>
                                            <p className="black-color font-16"> {"{usr.member_name}"} </p>
                                        </div>
                                        <div className="col-5 col-md-5 text-left mb-2">
                                            <p className="black-color font-14 mb-0"> Relation </p>
                                            <p className="black-color font-16"> {"{usr.relation}"} </p>
                                        </div>
                                    </div>
                                    :
                                    <h5 className="ng-star-inserted mb-0 py-3">No Family Members Found</h5>
                            }

                        </div>

                        <div className="py-3 border-bottom">
                            <div className="row">
                                <div className="col-8 col-sm-9">
                                    <h5 className="title black-color text-uppercase font-18 mb-0 pb-3"> Appointments details </h5>
                                </div>
                                <div className="col-4 col-sm-3 text-right">
                                    <a onClick="showSlotsBack()" className="brand-color1 font-14 font-w-600"> Change </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 text-left pb-3">
                                    <p className="black-color font-14 mb-0"> Instructor </p>
                                    <p className="black-color font-16"> {instDetails && instDetails.name ? instDetails.name + " " + instDetails.lastname : ""}  </p>
                                </div>
                                <div className="col-12 col-sm-6 text-left pb-3">
                                    <p className="black-color font-14 mb-0"> Duration </p>
                                    <p className="black-color font-16">{serviceDeatil && serviceDeatil.duration ? serviceDeatil.duration : ""}</p>
                                </div>
                                <div className="col-12 col-sm-6 text-left">
                                    <p className="black-color font-14 mb-0"> Date </p>
                                    <p className="black-color font-16"> {slotDateTime && slotDateTime.start_time ? moment(slotDateTime.start_time).format("DD MMM YYYY") : ""} </p>
                                </div>
                                <div className="col-12 col-sm-6 text-left">
                                    <p className="black-color font-14 mb-0"> Time </p>
                                    <p className="black-color font-16"> {slotDateTime && slotDateTime.slot ? slotDateTime.slot : ""} </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-3">
                            <div className="row">
                                <div className="col-12 text-left">
                                    <h5 className="title black-color text-uppercase font-16 mb-0 pb-3"> service details </h5>
                                </div>
                                <div className="col-12 text-left pb-2">
                                    <p className="black-color  font-14 mb-0"> Service Name : </p>
                                    <p className="black-color  font-16"> {serviceDeatil && serviceDeatil.service_name ? serviceDeatil.service_name : ""} </p>
                                </div>
                                <div className="col-12 text-left">
                                    <p className="black-color  font-14 mb-0"> Location </p>
                                    <p className="black-color  font-16"> {slotFullDetail && slotFullDetail.location_name ? slotFullDetail.location_name : ""}  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                    <div className="section-heading statbox-heading p-3 m-3 statbox">
                        <div className="pb-3 text-left border-t-lr-6">
                            <h5 className="title black-color text-uppercase font-18 mb-0"> payment details </h5>
                        </div>
                        <div className="pb-3 border-bottom">
                            <div className="row pb-3">
                                <div className="col-6 text-left">
                                    <p className="black-color font-16"> Appointment Fees: </p>
                                </div>
                                <div className="col-6 text-right">
                                    <p className="black-color font-16"> $ {serviceDeatil && serviceDeatil.amount ? serviceDeatil.amount : 0} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="black-color  font-16"> Tax1 </p>
                                </div>
                                <div className="col-6 text-right">
                                    <p className="black-color font-16">
                                        $ {serviceDeatil && serviceDeatil.amount && serviceDeatil.tax1_rate ?
                                            taxCalculator(serviceDeatil.amount, serviceDeatil.tax1_rate) : 0}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="black-color  font-16"> Tax2 </p>
                                </div>
                                <div className="col-6 text-right">
                                    <p className="black-color font-16">
                                        $ {serviceDeatil && serviceDeatil.amount && serviceDeatil.tax2_rate ?
                                            taxCalculator(serviceDeatil.amount, serviceDeatil.tax2_rate) : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 border-bottom">
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="black-color  font-16 mb-0"> Amount Payable </p>
                                </div>
                                <div className="col-6 text-right">
                                    <p className="black-color font-16 mb-0"> $
                                        {serviceDeatil && serviceDeatil.amount ?
                                            grandTotal(serviceDeatil.amount, taxCalculator(serviceDeatil.amount, serviceDeatil.tax1_rate), taxCalculator(serviceDeatil.amount, serviceDeatil.tax2_rate)) : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-3">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <button type="button" onClick={finalSubmitHandler} className="btn d-block w-100 btn-blue-grad text-uppercase"> Confirm Booking </button>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <button className="btn btn-blue-grad d-block w-100 text-uppercase" onClick={() => setStep(0)}> Cancel </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pt-1 pb-3">
                        <p onClick={cancelHandler} style={{ cursor: "pointer" }}> View Cancellation Policy </p>
                    </div>
                </div>
            </div>
            {/* </ng-container> */}
            {/* <!-- End Of Step 4 --> */}

        </>
    )
}
