import React, { useContext } from 'react'
import { MyContext } from '../../../App';
import { Link } from 'react-router-dom';

export const Innerfooter = () => {

  const contaxtres = useContext('MyContext');
  const youtubeUrl = "#"
  
  return (
  <>
  {/* <!-- Footer --> */}
<footer className="footer-customer" >
    <div className="container">
      <div className="footer-top py-3">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
            <div className="footer-left">
              <ul>
                <li> <a routerLink="/about-us" className="header-css"> About us </a> </li>
                <li> <a routerLink="/privacy-policy" className="header-css"> Privacy Policy </a> </li>
                <li> <a routerLink="/terms-conditions" className="header-css"> Terms & Conditions </a> </li>
                <li>
                  {/* <!-- <a routerLink="/faq" className="header-css"> FAQ </a> --> */}
                  <Link href={youtubeUrl} target="_blank" className="header-css"> FAQ </Link>
                 </li>
                <li> <a routerLink="/help" className="header-css"> Help </a> </li>
                <li> <a routerLink="/contact-us" className="header-css"> Contact us </a> </li>
              </ul>
            </div>
          </div>
          {/* <!-- end of footer-left --> */}

          <div className="col-12 col-md-4 col-lg-4">
            <div className="footer-right">
              <ul>
                <li>
                  <a href="https://www.instagram.com/warriorsms/?hl=en" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-instagram font-12"></span>
                    </div>
                  </a>
                </li>
                {/* <!-- instagram --> */}
                <li>
                  <Link href="https://www.facebook.com/WarriorStudioManagementSoftware" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-facebook font-12"></span>
                    </div>
                  </Link>
                </li>
                {/* <!-- facebook --> */}
                {/* <!-- <li>
                  <a href="Javascript:void(0);">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-youtube font-12"></span>
                    </div>
                  </a>
                </li> --> */}
                {/* <!-- Youtube -->s */}
                {/* <!-- <li>
                  <a href="Javascript:void(0);">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-linkedin font-12"><span className="path1"></span><span className="path2"></span></span>
                    </div>
                  </a>
                </li> --> */}
                {/* <!-- linkedin --> */}
                <li>
                  <Link href="https://twitter.com/warrior_sms?lang=en" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-twitter font-12"></span>
                    </div>
                  </Link>
                </li>
                {/* <!-- twitter --> */}
              </ul>
            </div>
          </div>
          {/* <!-- end of footer-right --> */}
        </div>
      </div>
      {/* <!-- end of footer-top --> */}
      <div className="footer-bottom pt-3">
        <div className="row">
          <div className="col-12">
            <div className="footer-copyright">
              <p className="brand-color3 mb-2"> Copyright &#169; 2025 Warrior SMS. All rights reserved. </p>
              <p className="brand-color3 mb-2">The Clover name and logo are registered trademarks owned by Clover Network, LLC. These registered trademarks are also utilized by Fiserv Canada Ltd.  Warrior Payments Inc. operates as an Agent of Fiserv Canada Ltd. All trademarks, service marks, and brand names mentioned in this document are the exclusive property of their respective owners. </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of footer-bottom --> */}
    </div>
    {/* <!-- end of container --> */}
  </footer>
{/* <!-- End of Footer Section -->s */}

  </>
  )
}
