import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap'
import { SharedButton } from '../../../../../sharedComponents/Button';
import { InputField } from '../../../../../sharedComponents/InputField';
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect';
import Swal from 'sweetalert2';
import logo from '../../../../../Images/logo_white_web.png';
import { buy_now_workshop_cash, clover_buy_now_workshop, generateToken } from '../../../../../Studio-Services/Studio_Services';
import { ExpiryCheck } from '../../../../../Static_Services/Staff_Services';
// import { Test } from '../../../../../Test';


export const WorkShopBox = ({ data, workData, setLoder, setWorkData, searchReset, setSerchvalue, scheduleWorkshopList }) => {
    const grandTotal = (amount, tax) => {
        const total = +amount + +tax;
        const grand = total.toFixed(2);
        return grand;
    }
    const [selectCustomer, setSelectCustomer] = useState();
    const getProductPrice = (amount, tax) => {
        if (tax === 0) {
            return 0;
        } else {
            const taxAmount = (+tax / 100) * +amount;
            return taxAmount;
        }
    }
    const calculateTax = (object) => {
        let tax = 0;
        if (object.tax1 === 'yes') {
            tax += +object.tax1_rate;
        }
        if (object.tax2 === 'yes') {
            tax += +object.tax2_rate;
        }
        return parseFloat(getProductPrice(object.price, tax)).toFixed(2);
    }

    const [userDetail, setUserDetail] = useState();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('st_userData'))) {
            setUserDetail(JSON.parse(localStorage.getItem('st_userData')));
        }
    }, [])

    const [preData, setPreData] = useState([]);
    const [clintId, setClintId] = useState();
    const [checkOut, setCheckOut] = useState(false);
    const [cartdetail, setCartdetail] = useState();
    const [paytype, setPaytype] = useState();

    useEffect(() => {
        setPreData(data);
    }, [data])

    const cartPuchase = (data) => {
        setSelectCustomer(data);
        setClintId(data.id);
        preData.forEach(e => {
            if (e.data.id === data.id) {
                setCartdetail(e.data);
            }
        })
    }

    useState(() => {
        if (preData.length <= 0) {
            setClintId();
        }
    }, [preData])


    const paymodeOP = [
        {
            value: "Cash", label: "Cash"
        },
        {
            value: "POS", label: "POS"
        },
        {
            value: "Pass", label: "Pass"
        },
        {
            value: "GiftCard", label: "GiftCard"
        },
        {
            value: "Check", label: "Check"
        },
        {
            value: "Direct Debit", label: "Direct Debit"
        }
    ]

    // Payment Card Code
    const [expiryDate, setExpiryDate] = useState();

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (inputValue.endsWith('/')) {
                setExpiryDate(inputValue.slice(0, -1));
            } else { setExpiryDate(inputValue); }
        } else {
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setExpiryDate(inputValue + '/');
            } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
        }
    };

    const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
    const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "" });

    const CardInputChange = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/^\s+/g, '');
        setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
        setCarderror((pre) => ({ ...pre, [name]: "" }));
    }


    const [error, setError] = useState({
        cardName: "",
        cardNumber: "",
        valid: "",
        cvv: "",
        mode: "",
        transactionid: "",
        notes: "",
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        let isValid = 1;
        const fData = new FormData(e.target);
        const fValue = Object.fromEntries(fData.entries());

        const PayMode = fValue.mode ? fValue.mode : "";
        const transactionid = fValue.transactionid ? fValue.transactionid : "";
        const notes = fValue.notes ? fValue.notes : "";

        let expiry_month = '';
        let expiry_year = '';
        let expired = '';

        if (expiryDate) {
            expired = ExpiryCheck(expiryDate);
        }
        if (expired) {
            expiry_month = expired[0];
            expiry_year = expired[1];
        } else {
            setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
            isValid = 2;
        }

        if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
        if (!cardData.crnumber) {
            setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
            isValid = 4;
        };
        if (!cardData.cvv) {
            setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
            isValid = 5;
        };

        if (!PayMode) { setError((pre) => ({ ...pre, mode: "*Required" })); isValid = 2 }
        if (!transactionid) { setError((pre) => ({ ...pre, transactionid: "*Required" })); isValid = 2 }
        // if (!notes) { setError((pre) => ({ ...pre, notes: "*Required" })); isValid = 2 }

        const tex1Amount = parseFloat(getProductPrice(workData.price, workData.tax1_rate)).toFixed(2);
        const tex2Amount = parseFloat(getProductPrice(workData.price, workData.tax2_rate)).toFixed(2);
        const finalAmount = grandTotal(workData.price, calculateTax(workData));


        if (transactionid && PayMode) {
            setLoder(true);       

            let tempObj = {};
            tempObj.amount = grandTotal(workData.price, +calculateTax(workData));
            tempObj.customer_id = selectCustomer.id;
            tempObj.payment_transaction_id = notes || 'none';
            tempObj.transaction_id = transactionid;
            tempObj.workshop_id = workData.workshop_id;
            tempObj.schedule_id = workData.id;
            tempObj.payment_mode = PayMode;

            tempObj.finalBaseAmount = workData?.price;
            tempObj.finalPrice = workData?.price;
            tempObj.finalDiscount = 0;
            tempObj.finalAdjustPrice = workData?.price;
            tempObj.finalTax1 = tex1Amount;
            tempObj.finalTax2 = tex2Amount;
            tempObj.finalSubTotal = finalAmount;

            const resp = await buy_now_workshop_cash(tempObj);
            if (resp) {
                setLoder(false);
                e.target.reset();
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                        <h4 class="title text-uppercase text-center mt-4 textWhite">
                        SUCCESS
                      </h4>
                      <p class="text-center my-4 textcolor">
                      <small class="textWhite">
                     ${resp.data.message} </small></p>
                      `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
                setPaytype();
                searchReset();
                setSerchvalue();
                const customerInfo = resp.data.data;
                const rowObject = workData;
                rowObject.customer_detail = customerInfo;
                rowObject.used = +rowObject.used + 1;
                setWorkData(rowObject)
            } else setLoder(false);
        } else if (cardData.name && cardData.crnumber && cardData.cvv && expiry_month && expiry_year) {
            setLoder(true);
            var paymentData = {
                "number": cardData.crnumber,
                "expiry_month": expiry_month,
                "expiry_year": expiry_year,
                "cvd": cardData.cvv,
                "business_id": userDetail.business_id,
                "country_code": '',
            }
            const response = await generateToken(paymentData)
            if (response) {
                setLoder(false);
                const tempObj = {};
                tempObj.workshop_id = workData.workshop_id;
                tempObj.schedule_id = workData.id;
                tempObj.customer_id = selectCustomer.id;
                tempObj.amount = grandTotal(workData.price, +calculateTax(workData));
                tempObj.savecard = 0;
                tempObj.card_id = "";
                tempObj.token = response.data.data.token;
                tempObj.business_id = "";
                tempObj.country_code = '';

                tempObj.finalBaseAmount = workData?.price;
                tempObj.finalPrice = workData?.price;
                tempObj.finalDiscount = 0;
                tempObj.finalAdjustPrice = workData?.price;
                tempObj.finalTax1 = tex1Amount;
                tempObj.finalTax2 = tex2Amount;
                tempObj.finalSubTotal = finalAmount;

                const finalResp = await clover_buy_now_workshop(tempObj);
                if (finalResp) {
                    let resp = finalResp;
                    Swal.fire({
                        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                            <h4 class="title text-uppercase text-center mt-4 textWhite">
                            SUCCESS
                          </h4>
                          <p class="text-center my-4 textcolor">
                          <small class="textWhite">
                         ${resp.data.message} </small></p>
                          `,
                        showClass: {
                            popup: 'custom-dialog-container',
                        },
                        customClass: {
                            confirmButton: 'custom-button custom-button-size modalbtn',
                        },
                    });
                    setPaytype();
                    searchReset();
                    setSerchvalue();
                    scheduleWorkshopList();
                    setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
                    setLoder(false);
                    // getScheduleInfo();
                } else setLoder(false);
            } else {
                setLoder(false);
            }
        }
    }

    const modeHandler = (e) => {
        const value = e.target.value;
        setError((pre) => ({ ...pre, mode: "", }));
    }
    const errorHandler = (e) => {
        const { name, value } = e.target;
        setError((pre) => ({
            ...pre,
            [name]: "",
        }));
    };
    return (
        <>
            {!cartdetail ?
                <>
                    <Row>
                        {preData.length > 0 ? preData.map((value, index) => (
                            <Col md={4} key={index}>
                                <Card className="ServiceCardBox" key={index}>
                                    <div className="cardImg">
                                        <Card.Img variant="top" src={value.profile_img} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='mb-0'>{value.name}</Card.Title>
                                        <small className="m-0">{value.gender ? value.gender : "No"}</small>
                                        <Card.Text>
                                            <p className="m-0">{value.email}</p>
                                        </Card.Text>
                                        <SharedButton onClick={() => cartPuchase(value.data)} title={"Purchase"} variant={"primary"} className={"btn btn-secondary purchasePassBtn"} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                            : ""}
                    </Row>
                    <Row>
                        <Col>
                            <SharedButton
                                className={"w-100"}
                                href={"/workshop-schedule"}
                                title={"Back"}
                                variant={"primary"}
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                </>
                :
                <>
                    <Row>
                        <Col md={4}>
                            <Card className="ServiceCardBox" style={{ height: "unset", background: "#0e8543", color: "#fff", boxShadow: "0 2px 5px 0 rgba(14, 133, 66, 0.2)" }} key={cartdetail.id}>
                                <div className="cardImg">
                                    <Card.Img variant="top" src={cartdetail.profile_img} />
                                </div>
                                <Card.Body>
                                    <Card.Title className='mb-0'>{cartdetail.name}</Card.Title>
                                    <Card.Text>
                                        <p className="m-0">{cartdetail.email}</p>
                                        <p className='m-0'>{cartdetail.mobile}</p>
                                        <p className='m-0'>{cartdetail.gender ? cartdetail.gender : "No"}</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-4 mb-4'>
                        <hr style={{ overflow: "hidden" }} />
                        <h4>Payment Details</h4>
                        <Col>
                            <div className="TextDetail text-center">
                                <h6>Workshop Fees</h6>
                                <p>$ {workData.price}</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail text-center">
                                <h6>Workshop Tax 1</h6>
                                <p>$ {parseFloat(getProductPrice(workData.price, workData.tax1_rate)).toFixed(2)}</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail text-center">
                                <h6>Workshop Tax 2</h6>
                                <p>$ {parseFloat(getProductPrice(workData.price, workData.tax2_rate)).toFixed(2)}</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail text-center">
                                <h6>Amount Payable</h6>
                                <p>$ {grandTotal(workData.price, calculateTax(workData))}</p>
                            </div>
                        </Col>
                        <hr style={{ overflow: "hidden" }} className='mt-3' />
                    </Row>
                    {checkOut ?

                        <Row className='mt-4 mb-4'>
                            <h4>Credit card / Debit card</h4>
                            <Row>
                                <p>Payment Mode</p>
                                <Row style={{ justifyContent: "center" }}>
                                    <Col md={3}>
                                        <button type='button' onClick={() => setPaytype(1)} className='ownCartBtn'>Other</button>
                                    </Col>
                                    <Col md={3}>
                                        <button type='button' onClick={() => setPaytype(2)} className='ownCartBtn'>Card</button>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className='mt-5' style={{ justifyContent: "center" }}>
                                <Form autoComplete='off' onSubmit={submitHandler}>
                                    {paytype === 1 ?
                                        <>
                                            <Row>
                                                <Col>
                                                    <SharedSelect
                                                        name={"mode"}
                                                        lable={"Payment Mode"}
                                                        error={error.mode}
                                                        onChange={modeHandler}
                                                        options={paymodeOP}
                                                    />
                                                </Col>
                                                <Col>
                                                    <InputField
                                                        type={"text"}
                                                        name={"transactionid"}
                                                        error={error.transactionid}
                                                        lable={"Transaction ID"}
                                                        onChange={errorHandler}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputField
                                                        type={"text"}
                                                        name={"notes"}
                                                        error={error.notes}
                                                        lable={"Notes"}
                                                        onChange={errorHandler}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                        : ""}
                                    {paytype === 2 ?
                                        <>
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            name='name'
                                                            placeholder="Sophie Joe"
                                                            value={cardData.name}
                                                            onChange={CardInputChange}
                                                        />
                                                        <small className="text-danger">{carderror.name}</small>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-group client-buy-input-cardno">
                                                        <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="4121 4411 3131 8554"
                                                            name='crnumber'
                                                            value={cardData.crnumber}
                                                            onChange={CardInputChange}
                                                            maxLength={19}
                                                        />
                                                        <small className="text-danger">{carderror.crnumber}</small>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                                        <input
                                                            type="text"
                                                            name={'dates'}
                                                            className="form-control"
                                                            placeholder="MM/YYYY"
                                                            value={expiryDate}
                                                            onChange={handleInputChange}
                                                            pattern="^[0-9]{2}/[0-9]{4}"
                                                            autoComplete="new-password"
                                                            lable={"Valid Through"}
                                                        />
                                                        <small className="text-danger">{carderror.dates}</small>
                                                    </div>

                                                </Col>
                                                <Col>
                                                    <div className="form-group">
                                                        <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                                                        <input
                                                            type="password"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="eg. 201"
                                                            name='cvv'
                                                            value={cardData.cvv}
                                                            onChange={CardInputChange}
                                                            maxLength={3}
                                                        />
                                                        <small className="text-danger">{carderror.cvv}</small>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        : ""}
                                    <Row className='mt-3'>
                                        <Col>
                                            <SharedButton
                                                className={"w-100"}
                                                href={"/workshop-schedule"}
                                                title={"Back"}
                                                variant={"primary"}
                                            />
                                        </Col>
                                        <Col>
                                            <SharedButton
                                                className={"w-100"}
                                                type={"submit"}
                                                title={"Proceed To Pay"}
                                                variant={"primary"}
                                            />
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Form>
                            </Row>
                        </Row>
                        :
                        <>
                            <Row>
                                <Col>
                                    <SharedButton
                                        className={"w-100"}
                                        href={"/workshop-schedule"}
                                        title={"Back"}
                                        variant={"primary"}
                                    />
                                </Col>
                                <Col>
                                    <SharedButton
                                        className={"w-100"}
                                        onClick={() => setCheckOut(true)}
                                        title={"Check Out"}
                                        variant={"primary"}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                        </>
                    }



                </>
            }
        </>
    )
}
